import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { Platform } from '@angular/cdk/platform';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AsideComponent } from './aside/aside.component';
import { LogoutComponent } from './logout/logout.component';
import { HistoryManagerComponent } from './history-manager/history-manager.component';
import { TyperPipe } from './typer.pipe';
import { ServicesManagerComponent } from './services-manager/services-manager.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FieldPipe } from './field.pipe';
import { StatusPipe } from './status.pipe';
import { BillsComponent } from './bills/bills.component';
import { CreateBillComponent } from './create-bill/create-bill.component';
import { CounterPipe } from './counter.pipe';
import { FundingComponent } from './funding/funding.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    DashboardComponent,
    AsideComponent,
    LogoutComponent,
    HistoryManagerComponent,
    TyperPipe,
    ServicesManagerComponent,
    FieldPipe,
    StatusPipe,
    BillsComponent,
    CreateBillComponent,
    CounterPipe,
    FundingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    BrowserAnimationsModule
  ],
  providers: [Platform],
  bootstrap: [AppComponent]
})
export class AppModule {

}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
