function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}



export class Field {
    name : string;
    type : string;
    label : string;
    required : boolean;
    values : string;
    options : string;
    isselect : boolean;
    istextarea : boolean;

    constructor(obj : any) {
        if(obj){
            Object.assign(this,obj)
        }else {
            this.name = "";
            this.type = "";
            this.required = true;
            this.values = "";
            this.options = "";
            this.isselect = false;
            this.istextarea = false;
        }
    }

    isCorrect() {
        var amount_checker = true;

        if(!(this.name === 'amount')) {

            if(!this.label) return false;
            if(!this.type) return false;

        } else {
           /* var reg = /^\d+(\.\d{1,2})?$/g;
            var list = this.values.split(";");
            list.forEach(amount => {
                if(!amount.match(reg)) amount_checker = false;
            });*/

        }

        if(this.isselect){
            if(!this.values) return false;
        }

        return true && amount_checker;
    }


    setOptions(){
        this.options = "";
        if(!(this.name === 'amount')) {
            if(this.values) {
                var list = this.values.split(";");
                if(list.length >= 1 ) {
                    for (let index = 0; index < list.length; index++) {
                        const name = list[index].trim();
                        if(name.length > 0){
                            const value = name.replace(/[^a-zA-Z0-9]/g, "").replace(" ","_");
                            this.options += name+":"+value;
                            if(index < list.length-1) this.options += ";";
                        }
                    }
                }
            }
        }else {
            this.options = this.values;
        }

    }

    setName() {
        if(!(this.name === 'amount')) {
            this.name = this.label.trim().replace(/[^a-zA-Z0-9]/g, "").replace(" ","_") + "_" + makeid(4);
            return;
        }
    }

    setValues(){
        this.values = "";


        if(!(this.name === 'amount')) {
            if(this.options) {
                var list = this.options.split(";");
                for (let index = 0; index < list.length; index++) {
                    var val = list[index].split(":");
                    if(this.values.length > 0) this.values += ";";
                    if(val[1]) this.values += val[1];
                }
            }
        }else {
            this.values = this.options;
        }
    }
}

export class Service {
    ids : number;
    name : string;
    amount : number;
    isamount : boolean;
    notifurl : string;
    formBuilds : Array<Field>;
    status : string;
    slug : string;
    haveSelectAmount : boolean;
    withemail : boolean;
    textemail : string;

    constructor(obj : any) {
        if(obj){
            Object.assign(this, obj);
            if(this.formBuilds){
              for (let i = 0; i < this.formBuilds.length; i++) {
                this.formBuilds[i] = new Field(this.formBuilds[i]);
              }
            }
        } else {
            this.ids = null;
            this.name = "";
            this.amount = 0;
            this.notifurl = "";
            this.isamount = true;
            this.formBuilds = [];
            this.status = "";
            this.slug = "";
            this.haveSelectAmount = false;
        }
    }

    getLetter() {
        return this.name.trim().replace(/[^a-zA-Z ]/g, "").replace(" ","_").substring(0,1);
    }

    setOptions() {
        if(this.formBuilds){
          this.formBuilds.forEach(field => {
            field.setOptions()
        });
        }
    }

    setValues() {
        if(this.formBuilds){
          this.formBuilds.forEach(field => {
            field.setValues()
        });
        }
    }

    isCorrect() {

        if(!this.name) return false;
        if(!this.isamount){
            if (!this.amount) return false;
        }

        if(this.withemail){
            if(!this.textemail){
              return false;
            }
      
            if(this.textemail.length < 10) {
              return false;
            }
        }

        return true;
    }

    setNames(){
        if(this.formBuilds){
          this.formBuilds.forEach(field => {
            field.setName();
        })
        }
    }

    getAmountField() {
        if(this.formBuilds){
          for (let index = 0; index < this.formBuilds.length; index++) {
            if(this.formBuilds[index].name === 'amount') return index;
          }
        }

        return -1;
    }

    deleteField(i : number) {
        if(i > -1) {
            if(this.formBuilds[i].name === 'amount') this.haveSelectAmount = false;
            this.formBuilds.splice(i,1);
        }
    }

    deleteAmount() {
        this.haveSelectAmount = false;
        this.deleteField(this.getAmountField());
    }
}
