import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpService: HttpService) {  }


  getIsLoggedIn() {
    if (localStorage.getItem('IS_MERCHAND_LOGGED_IN') === 'TRUE') { return true; }
    return false;
  }

  logout() {
    localStorage.clear();
  }

  login(login: string, password : string) {
   return this.httpService.post('ecommerce/login/', JSON.stringify({login,password}));
  }

  saveUserData(data: any) {
      localStorage.setItem('MERCHAND', JSON.stringify(data));
      localStorage.setItem('IS_MERCHAND_LOGGED_IN', 'TRUE');
  }

  getUserData() {
      return JSON.parse(localStorage.getItem('MERCHAND'));
  }

  changePassword(data : any) {
    return this.httpService.post('ecommerce/store/password/modify/', JSON.stringify(data));
  }

  resetPassword(data : any) {
    return this.httpService.post('ecommerce/store/password/reset/', JSON.stringify(data));
  }

  saveContacts(data : any) {
    return this.httpService.post('ecommerce/edit/contact/', JSON.stringify(data));
  }

  generateKey(data : any) {
    return this.httpService.post('ecommerce/changekey/', JSON.stringify(data));
  }
}
