import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth.service';
import {FormControl} from '@angular/forms';
import { HelperService } from '../helper.service';
import { Bill, Item} from '../classes/Bill';
import { environment } from 'src/environments/environment';
import * as _ from "lodash";
import { BillService } from '../bill.service';
import { NgNavigatorShareService } from 'ng-navigator-share';
declare var $:any;

@Component({
  selector: 'app-bills',
  templateUrl: './bills.component.html',
  styleUrls: ['./bills.component.css']
})

export class BillsComponent implements OnInit {
  merchand : any;
  bill : Bill;
  bills : Array<Bill>;
  showItemform = false;
  currentItem : Item;
  titleError = false;
  priceError = false;
  unitsError = false;
  title = new FormControl();
  price = new FormControl();
  units =new FormControl();
  details = new FormControl();
  saving = false;
  editedItem = -1;
  editedBill = -1;
  billError = false;
  itemError = false;
  message  : any;
  loader = false;
  btntitle = 'copy';
  loading_bill = false;

  constructor(private auth : AuthService, private helper : HelperService, private biller : BillService
    , private ngNavigatorShareService: NgNavigatorShareService) { }

  ngOnInit() {
    this.merchand = this.auth.getUserData();
    this.bills = [];
    this.getBills();
  }

  initBill() {
    delete this.message;
    this.editedBill = -1;
    this.editedItem = -1;
    this.bill = new Bill(null);
    $("#billEditModal").modal("show");
  }

  canUseNavigatorShare(){
    return this.helper.canUseNavigatorShare();
  }

  share(url : string){
    this.ngNavigatorShareService.share({
      title:  this.merchand.name,
      text: 'AfrikEcommerce',
      url: url,
    }).then( (response) => {
      console.log(response);
    })
    .catch( (error) => {
      console.log(error);
    });
  }

  copy(url : string) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(url).select();
    document.execCommand("copy");
    $temp.remove();
    this.btntitle = 'copied';
    setTimeout(() => {
      this.btntitle = 'copy';
    }, 2500);
  }

  numberize(i: number){
    return i < 10 ? "0"+i : i;
  }

  deleteMessager(){
    delete this.message;
    delete this.bill;
    delete this.currentItem;
    this.editedItem = -1;
    this.editedBill = -1;
    this.billError = false;
    this.itemError = false;
  }

  isMobile(){
    return this.helper.isMobile();
  }

  showItemForm() {
    this.showItemform = true;
  }

  spliter(s : string) {
    return s ? s.split(";") : [];
  }

  getEmail(){
    let temp = this.spliter(this.merchand.email);
    return temp.length > 0 ? temp[0] : "";
  }

  isCorrectEmail(email : string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email.trim()).toLowerCase());
  }

  hasMinSize(value : string, size  : number){
    return value.length >= size;
  }


  getPhone(){
    let temp = this.spliter(this.merchand.contact);
    return temp.length > 0 ? temp[0] : "";
  }

  formatIndex(){
    let temp = this.spliter(this.merchand.contact);
    return temp.length > 0 ? temp[0] : "";
  }

  addItem() {
      let item = {
        id : null,
        title : this.title.value? this.title.value.trim() : '',
        price : this.price.value,
        details :  this.details.value ? this.details.value.trim() : "",
        units : this.units.value
      }
      this.itemError = false;

      if(item.title.length < 1 || item.price < 1 || item.units < 1 ) {
        this.itemError = true;
        return;
      }

      if(this.editedItem > -1) {
        item.id = this.bill.items[this.editedItem].ids;
        this.bill.updateItem(this.editedItem, new Item(item));
        this.editedItem = -1;
      } else {
        this.bill.addItem(new Item(item));
      }
      this.closeItemForm();
  }

  closeItemForm() {
    this.title.setValue("");
    this.price.setValue(0);
    this.details.setValue("");
    this.units.setValue(0);
    this.editedItem = -1;
    this.showItemform = false;
  }

  editItem(i : number) {
    this.editedItem = i;
    this.showItemform = true;

    let item = this.bill.items[i];
    this.title.setValue(item.title);
    this.price.setValue(item.price);
    this.details.setValue(item.details);
    this.units.setValue(item.units);
  }

  previewBill(i : number, action : string){

    delete this.message;
    this.bill = new Bill(this.bills[i]);
    this.saving = true;
    this.loading_bill = true;

    if(action == 'edit'){
      this.editedBill = i;
      $("#billEditModal").modal("show");
    }else {
      $("#billModal").modal("show");
    }

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      billid : this.bill.ids
    };

    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.biller.getBill(data).subscribe((response : any) => {

      this.saving = false;
      if(response){
        if(response.code == 200) {

          this.bill = new Bill(response.result);
          this.loading_bill = false;

        } else {
          this.message = {
            type : 0,
            text : this.isServerError(response.message) ? 'service_issue': response.message
          }
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 2,
        text : ""
      }
    });
  }


  saveBill(){
    delete this.message;

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      bill : this.bill
    };

    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.closeItemForm();
    this.saving = true;
    this.bill.store = this.merchand.code;
    let save = this.editedBill > -1 ? this.biller.update : this.biller.create;
    save(data).subscribe((response : any) => {
      this.saving = false;
      if(response){
        if(response.code == 200) {
          $("#billEditModal").modal("hide");
          this.bill = null;
          //this.editedBill > -1 ? this.bills[this.editedBill] = new Bill(response.result) : this.bills.unshift(new Bill(response.result));
          this.editedItem = -1;
          delete this.bills;
          this.getBills();
          this.message = {
            type : 1,
            text :''
          }
        } else {
          this.message = {
            type : 0,
            text : this.isServerError(response.message) ? 'service_issue': response.message
          }
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 2,
        text : ""
      }
    });

  }

  deleteBill(bill : Bill){
    delete this.message;
    $("#delModal").modal("show");

    if(bill){
      this.bill = new Bill(bill);
    }


    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      id : this.bill.ids
    };

    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.biller.delete(data).subscribe((response : any) => {
      if(response){
        if(response.code == 200) {
          delete this.bills;
          this.getBills();
          this.message = {
            type : 1,
            text :''
          }
        } else {
          this.message = {
            type : 0,
            text : this.isServerError(response.message) ? 'service_issue': response.message
          }
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 2,
        text : ""
      }
    });

  }




  getBill = (bill : Bill) => {
    delete this.message;

    this.bill = bill;
    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      billid : this.bill.ids
    };

    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.biller.getBill(data).subscribe((response : any) => {
      if(response){
        if(response.code == 200) {

          this.bill = new Bill(response.result);

        } else {
          this.message = {
            type : 0,
            text : this.isServerError(response.message) ? 'service_issue': response.message
          }
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 2,
        text : ""
      }
    });

  }

  getBills(){
    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : ""
    };

    this.loader = true;

    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.biller.getAll(data).subscribe((response : any) => {
      this.loader = false;
      if(response) {
        if (response.code == 200) {
          this.bills = [];
          response.result.forEach(bill => {
            if(!(bill.status === 'ARCHIVED')) this.bills.push(new Bill(bill));
          });
        }
      }
    }, (error : any) =>{
      console.log(error);
    })
  }

  isServerError(s : string){
    return s.includes("HTTP/1.1");
  }

  getRealPosition(ids : number){

    for(let i = 0; i< this.bills.length; i++) {
      if(this.bills[i].ids === ids) return i;
    }
    return -1;
  }

}
