import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth.service';
import {HistoryService} from '../history.service';
import { Router } from '@angular/router';
import {HelperService} from '../helper.service';
import {Md5} from 'ts-md5/dist/md5';
import { environment } from 'src/environments/environment';
declare var $: any;

import  * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { OperationService } from '../operation.service';
import { areAllEquivalent } from '@angular/compiler/src/output/output_ast';
import { valHooks } from 'jquery';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  merchand : any;
  transactions : [];
  loader = false ;
  account = new FormControl('');
  accountConfirm = new FormControl('');
  code = new FormControl('');
  amount = new FormControl('');
  description = new FormControl('');
  contact = new FormControl('');
  email = new FormControl('');
  bank = new FormControl('');
  coderefund = new FormControl('');
  message : any;
  amountError = false;
  accountError= false;
  accountCError = false;
  codeError = false;
  accountChecked : string;
  countryCode = new FormControl("237");
  loadingRefund = true;
  refundList  = [];
  currentRefund : number;
  approve = true;
  method : string;
  askCode = false;
  codeRefundError = false;
  data_temp = null;
  page = 1;
  maxPage : number;
  optionOTP = false;

  constructor(private auth : AuthService, private history : HistoryService, private router : Router,
    private helper : HelperService, private operation : OperationService) { }

  ngOnInit() {
    this.merchand = this.auth.getUserData();
    if(!this.merchand) this.router.navigateByUrl('logout');
    this.getHistory();
    this.setOtpOtions();
    console.log(this.merchand);
  }


  setOtpOtions() {
    if(this.merchand.defaultCashier) {
      this.optionOTP = this.merchand.defaultCashier.optionotp;
    }
  }

  resetCode() {
    this.code = new FormControl('');
    this.askCode = false;
    this.page = 1;
  }

  spliter(s : string) {
    return s ? s.split(";") : [];
  }

  cancelDecision(){
    this.currentRefund = -1;
    this.approve = false;
    this.resetCode();
    delete this.message;
  }

  decide(i : number, decision : boolean){
    this.currentRefund = i;
    this.approve = decision;
    delete this.message;

    if(!this.approve) this.refundValidation()
  }

  getAccount(tr : any){
    var temp : string;
    temp = tr.account;
    if(temp.length > 5) {
      var lastCharactersPhone = temp.substring(temp.length - 2);
      var lastCharactersBank = temp.substring(temp.length-3);
      if(tr.method == 'bank') {
        return temp.substring(0,11)+"XXXXXXXXXXXX"+lastCharactersBank;
      }

      return temp.substring(0,2)+"............"+lastCharactersPhone;
    }

    return "XXXXXXXXX"
  }

  formatText(value) {
		if(!value) return '';

		var output = '';
		var str = value;

		var rest = str.length % 4;
		var part = Math.trunc(str.length / 4);

		if (str.length > 4) {
			output += str.substring(0, rest);
			str = str.slice(rest);

			for (var i = 0; i < part; i++) {
				output += ' ';
				output += str.substring(0, 4);
				str = str.slice(4);
			}
		} else {
			output = str;
		}

		return output;
  }

  formatBankText(value) {
    if(!value) return '';

    let n = this.extractBankName(value);
    value = this.extractBankAccount(value);

		var output = '';
		var str = value;

		var rest = str.length % 4;
		var part = Math.trunc(str.length / 4);

		if (str.length > 4) {
			output += str.substring(0, rest);
			str = str.slice(rest);

			for (var i = 0; i < part; i++) {
				output += ' ';
				output += str.substring(0, 4);
				str = str.slice(4);
			}
		} else {
			output = str;
    }

    if(!n) return output;

		return n+"-"+output;
	}

  setMethod() {
    this.method = $('input[name=method]:checked').val();
  }

  isWithdrawFormCorrect() {

    if(!(parseInt(this._cleanString(this.amount.value))  >= 100)) {
      this.amountError = true;
      return false;
    }

    if(this.method === 'afrikpay'){
      if(this._cleanString(this.account.value).length < 9) {
        this.accountError = true;
        return false;
      }
    }

    if(!(this._cleanString(this.code.value).length === 4)) {
      this.codeError = true;
      return false;
    }

    return true;
  }

  checkRefundOption() {
    if(!this.merchand.defaultCashier.optionrefund) {
      $("#authoModal").modal("show");
      return;
    }else {
      $('#operationModal').modal('show');
    }
  }

  checkName() {

    if(!this.isWithdrawFormCorrect()) return;

    $('#operationModal').modal('hide');

    setTimeout(() => {
      $('#loaderModal').modal('show');
    }, 300);

    delete this.accountChecked;
    delete this.message;


    let account = this.method === 'afrikpay' ? this._cleanString(this.countryCode.value) + this._cleanString(this.account.value) : this.bank.value;

    this.operation.getName(account, this.method).subscribe((response : any) => {
      if(response){
        if(response.code === 200 ){
          if(response.name) this.accountChecked = response.name;
          this.message = {
            type : 2,
            text : ""
          };
        } else {
          this.message = {
            type : 0,
            text : response.message
          };
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })
  }

  refundValidation(){

    delete this.message;

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      code :  Md5.hashStr(this.code.value).toString(),
      hash:"",
      transactionid : this.currentRefund >= 0 ? this.refundList[this.currentRefund].ids : '',
      otp : this.coderefund.value
    };

    if(this.askCode) {
      data.code = this.data_temp.code;
      data.transactionid = this.data_temp.transactionid;
      this.approve = true;
      data.otp = this.coderefund.value;
    }


    data.hash = Md5.hashStr(data.transactionid+this.merchand.apikey).toString();
    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }


    var todo = this.approve ?  (data) => this.operation.validateRefund(data) : (data) => this.operation.rejectRefund(data);

    this.loadingRefund = true;
    todo(data).subscribe((response : any) => {

      this.approve = false;
      this.loadingRefund = false;

      if(response.code === 200){
        this.refundList.splice(this.currentRefund, 1);
        this.currentRefund = -1;
        this.coderefund.setValue("");
        this.message = {
          type : 1,
          text : ""
        };

      }else {
        this.message = {
          type : 0,
          text : response.message
        };
      }
    }), (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    };

  }

  extractBankAccount(s : string) {
    if(!s) return "";
    let array = s.split("-");
    return (array.length > 1) ? array[1] : s;
  }

  extractBankName(s : string) {
    if(!s) return "";
    let array = s.split("-");
    return (array.length > 1) ? array[0] : '';
  }

  refund() {

    delete this.message;

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      code : Md5.hashStr(this.code.value).toString(),
      account : this.method === 'afrikpay' ? this._cleanString(this.countryCode.value) + this._cleanString(this.account.value) : this.bank.value,
      provider : this.method,
      hash:"",
      amount : parseInt(this._cleanString(this.amount.value)),
      description : this.description.value,
      email : this.email.value,
      transactionid : ''
    };

    data.hash = "" + Md5.hashStr(data.provider+data.account+data.amount+this.merchand.apikey);

    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.operation.withdraw(data).subscribe((response : any) => {
      if(response){
        if(response.code === 200 ){
          this.message = {
            type : 1,
            text : ""
          };

        } else {
          if(response.code == 201) {

            this.askCode = true;
            data.transactionid = response.result.paymentref;
            this.data_temp = data;

            if(response.result.status === "PROGRESSOTP" ) {
              this.message = {
                type : 3,
                text : ""
              };
            } else {
              this.message = {
                type : 4,
                text : response.message
              }
            }
          } else {
            this.message = {
              type : 0,
              text : response.message
            }
          }
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })
  }

  getHistory() {
    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      hash : ""
    };

    data.hash = Md5.hashStr(data.store+this.merchand.apikey).toString();

    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.loader = true;

    this.history.getTransactions(data).subscribe((response : any) => {
      this.loader = false;
      if(response) {
        if(response.code == 200) {
          this.transactions = response.result
        }
      }

    })

  }

  prev() {
    this.page -=1;
    this.getRefund();
  }

  next(){
    this.page += 1;
    this.getRefund();
  }

  getRefund() {

    delete this.message;

    $('#validateModal').modal('show');

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      max : 4,
      current : this.page
    };

    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.refundList = [];

    this.loadingRefund = true;

    this.operation.getRefunds(data).subscribe((response : any) => {
      this.loadingRefund = false;
      if(response){
        if(response.code === 200 ){

          response.result.data.forEach(e => {

            if(e.type === "cashin"){
              this.refundList.push(e)
            }

          });

          this.maxPage = response.result.nbpage;

        } else {
          this.message = {
            type : 0,
            text : response.message
          };
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })

  }


  getDate(date : string){
    return moment(date).locale(localStorage.getItem("lang")).format('LLL');
  }

  isMobile() {
    return this.helper.isMobile();
  }

  resetFormErrors() {
    this.amountError = false;
    this.accountError = false;
    this.accountCError = false;
    this.codeError = false;
    this.codeRefundError = false;
  }


  _cleanString(str: any) {
    if(!str) return "";
    str = str.replace(/\s/g, '');

    var output = '';

    for (var i = 0; i < str.length; i++) {
      if (!isNaN(str[i])) output += str[i];
    }

    return output;
  }

  formatNumber(value, key) {
    var output = '';
    var str = this._cleanString(value);


    if (key === 'amount' && value != '0' && parseInt(value) > 0) {
      str = parseInt(str).toString();
    }

    var rest = str.length % 3;
    var part = Math.trunc(str.length / 3);

    if (str.length > 3) {
      output += str.substring(0, rest);
      str = str.slice(rest);

      for (var i = 0; i < part; i++) {
        output += ' ';
        output += str.substring(0, 3);
        str = str.slice(3);
      }
    } else {
      output = str;
    }

    return output;
  }

  onChangeText($event: any, key: string) {
    var value = $event.target.value;

    var output = this.formatNumber(value, key);


    switch (key) {
      case 'amount': {
        this.amount.setValue(output);
        break;
      }
      case 'account': {
        this.account.setValue(output);
        break;
      }
      case 'accountConfirm': {
        this.accountConfirm.setValue(output);
        break;
      }
    }
  }

}
