import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import {FormControl} from '@angular/forms';
import {AuthService} from '../auth.service';
import {Service, Field} from '../classes/Service';
import  * as moment from 'moment';
import '../helper/icons';
import { ServicesService } from '../services.service';
import { HelperService } from '../helper.service';
import { environment } from 'src/environments/environment';
import { NgNavigatorShareService } from 'ng-navigator-share';

import { DOCUMENT } from '@angular/common';
declare var $ : any;
declare var SUNEDITOR : any;

@Component({
  selector: 'app-services-manager',
  templateUrl: './services-manager.component.html',
  styleUrls: ['./services-manager.component.css']
})
export class ServicesManagerComponent implements OnInit {

  typeList = [
    {
      value : 'number',
      name : "numeric"
    },
    {
      value : 'text',
      name : "text"
    },
    {
      value : 'list',
      name : "list"
    },
    {
      value : 'textarea',
      name : 'textarea'
    },
    {
      value : 'date',
      name : 'date'
    },
    {
      value : 'email',
      name : 'email'
    },
    {
      value : 'file',
      name : 'file'
    },
    {
      value : 'time',
      name : 'time'
    }
  ]
  rootUrl : string;
  merchand : any;
  loading : any;
  services : Array<Service>;
  service : Service;
  creating = false;
  editing = false;
  publishing = false;
  disabling = false;
  saving = false;
  fcreating = false;
  fediting = false;
  show_field_error = false;
  show_service_error = false;
  field : Field;
  editedField : number;
  editedService : number;
  loader_all = true;
  message : {type : number, text : string};
  btntitle = 'copy';
  html : string;
  textemail_error = false;
  loading_bill = false;
  editor : any;


  constructor(private auth : AuthService, private serviceManager : ServicesService,
    private helper : HelperService, private ngNavigatorShareService: NgNavigatorShareService, 
    private renderer2 : Renderer2, @Inject(DOCUMENT) private _document) { }

  ngOnInit() {
    this.merchand = this.auth.getUserData();
    this.getAll();
    this.rootUrl = environment.rootUrl;
  }

  public options: Object = {
    charCounterCount: true,
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat','alert'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat','alert'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat','alert'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat','alert'],
  };

  copy() {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(this.getUrl()).select();
    document.execCommand("copy");
    $temp.remove();
    this.btntitle = 'copied';
    setTimeout(() => {
      this.btntitle = 'copy';
    }, 2500);
  }

  changeStatus(evt : any) {
    this.service.isamount = !evt.target.checked;
    if(!this.service.isamount) this.service.haveSelectAmount = false;
    this.service.deleteAmount();
  }

  setTextEmail(s : string) {
    this.service.textemail = s;
  }

  setEditor(){
    this.editor =  SUNEDITOR.create('textemail');
    var setText = (s) => this.setTextEmail(s);
    var service = this.service;
    this.editor.onChange = function (contents, core) {
      if(service) {
        setText(contents);
      }
    }
  }

  changeEmailStatus(evt : any){
    this.service.withemail = evt.target.checked;
    setTimeout(()=> {
      this.setEditor()
    },150);
  }

  changeStatusAmoutList(evt : any) {
    if($("#isselectamount").val()) {
      this.field = new Field(null);
      this.field.type = 'list';
      this.field.name = 'amount';
      this.field.label = 'Amount'
      this.fcreating = true;
      $("#fieldAmountModal").modal("show");
      this.service.haveSelectAmount = false;
      $("#isselectamount").prop("checked", false);
    } else {
      this.service.deleteAmount();
    }

  }

  requiredStatus(checked : boolean){
    this.field.required = checked;
  }

  cancelAll() {
    this.field = null;
    this.editing = false;
    this.creating = false;
    this.fcreating = false;
    this.fediting = false;
    this.creating = false;
    this.editing = false;
    this.publishing = false;
    this.saving = false;
    this.disabling = false;
    this.fcreating = false;
    this.fediting = false;
    this.show_field_error = false;
    this.show_service_error = false;
    delete this.service;
  }

  createService() {
    this.cancelAll();
    this.creating = true;
    this.service = new Service(null);
  }

  editService(i : number) {
    this.cancelAll();
    this.loading_bill = true;
    this.editedService = i;

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      slug : this.services[i].slug
    };
    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    };

    this.serviceManager.get(data).subscribe((response : any) => {
      this.loading_bill = false;
      if(response){
        if(response.code === 200 ){
          this.service = new Service(response.result);
          this.editing = true;
          setTimeout(()=> {
            this.setEditor()
          },150);
          this.message = {
            type : 1,
            text : ""
          };
        } else {
          this.message = {
            type : 0,
            text : response.message
          };
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })


  }



  deleteField(i : number) {
    if(i > -1) {
      if(this.service) {
        this.service.deleteField(i);
      }
    }
  }

  editField(i : number) {
    this.editedField = i;
    this.fediting = true;
    this.fcreating = false;
    if(this.service) {
      this.field = new Field(this.service.formBuilds[i]);
    }
    $("#fieldNewModal").modal('show');
  }

  resetFieldStatus(){
    this.fcreating = false;
    this.fediting = false;
  }

  newField() {
    this.fediting = false;
    this.fcreating = true;
    this.show_field_error = false;
    this.field = new Field(null);
  }

  isSelect(val : string) {
    if(val === 'list') {
      this.field.isselect = true;
    } else {
      this.field.isselect = false;
      if(val === 'textarea') {
        this.field.istextarea = true;
      } else {
        this.field.istextarea = false;
      }
    }
  }

  saveField() {
    this.show_field_error = true;
    if(!this.field.isCorrect()) return;

    $("#fieldNewModal").modal('hide');
    $("#fieldAmountModal").modal('hide');

    if(this.field.name === 'amount') {
      this.service.haveSelectAmount = true;
      this.service.isamount = true;
    }
    if(this.fcreating){
      this.service.formBuilds.push(this.field);
    } else {
      if(this.fediting) {
        this.service.formBuilds[this.editedField] = this.field;
        this.fediting = false;
      }
    }

    delete this.field;
  }





  saveIt(){ 
    delete this.message;
    this.show_service_error = true;
    this.textemail_error = !this.service.isCorrect() && this.service.withemail && !this.service.textemail;
    if(!this.service.isCorrect()) return;
    this.service.setOptions();
    this.service.setNames();
    $('#loadingModal').modal('show');
    this.saving = true;

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      service : this.service
    };
    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    };
    var todo = null;
    if(this.service.ids) {
      todo = this.serviceManager.update;
    }else {
      todo = this.serviceManager.save;
    }

    todo(data).subscribe((response : any) => {
      if(response){
        if(response.code === 200 ){

          this.service = new Service(response.result);
          this.service.setValues();
          if(this.creating) {
            this.services.unshift(this.service);
            $("#serviceContainer>div.active").removeClass("active");
          }
          if(this.editing) {
            this.services[this.editedService] = this.service;
          }
          this.saving = false;
          this.editing = true;
          this.creating = false;

          this.message = {
            type : 1,
            text : ""
          };
        } else {
          this.message = {
            type : 0,
            text : response.message
          };
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })
  }

  publishIt(){
    delete this.message;
    this.show_service_error = true;
    if(!this.service.isCorrect()) return;
    this.service.setOptions();
    this.service.setNames();
    $('#loadingModal').modal('show');
    this.saving = false;
    this.publishing = true;

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      service : this.service
    };
    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.serviceManager.publish(data).subscribe((response : any) => {
      if(response){
        if(response.code === 200 ){
          this.service = new Service(response.result);
          this.services[this.editedService] = this.service;
          this.publishing = false;
          this.message = {
            type : 1,
            text : ""
          };
        } else {
          this.message = {
            type : 0,
            text : response.message
          };
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })
  }

  disableIt(){
    delete this.message;
    this.show_service_error = true;
    if(!this.service.isCorrect()) return;
    this.service.setOptions();
    this.service.setNames();
    $('#loadingModal').modal('show');
    this.saving = false;
    this.publishing = false;
    this.disabling = true;

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      service : this.service
    };
    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.serviceManager.disbable(data).subscribe((response : any) => {
      if(response){
        if(response.code === 200 ){
          this.service = new Service(response.result);
          this.services[this.editedService] = this.service;
          this.disabling = false;
          this.message = {
            type : 1,
            text : ""
          };
        } else {
          this.message = {
            type : 0,
            text : response.message
          };
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })
  }

  getPreview(){
    delete this.message;
    delete this.html;
    $('#previewModal').modal('show');

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      service : this.service
    };
    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.serviceManager.preview(data).subscribe((response : any) => {
      if(response){
        if(true ){
          this.html = response.result;
        } else {
          this.message = {
            type : 0,
            text : response.message
          };
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })
  }

  getAll() {
    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : ""
    };
    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.loader_all = true;

    this.serviceManager.getList(data).subscribe((response : any) => {
      if(response){
        if(response.code === 200 ){
          this.services = response.result;
          for (let index = 0; index < this.services.length; index++) {
            this.services[index] = new Service(this.services[index]);
            this.services[index].setValues();
          }
          this.message = {
            type : 1,
            text : ""
          };
        } else {
          this.message = {
            type : 0,
            text : response.message
          };
        }
      }
      this.loader_all = false;
    }, (error : any) =>{
      console.log(error);
      this.loader_all = false;
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })
  }

  isMobile() {
    return this.helper.isMobile();
  }

  getUrl(){
    return this.rootUrl+this.merchand.slug+'/'+this.service.slug;
  }

  canUseNavigatorShare(){
    return this.helper.canUseNavigatorShare();
  }

  share(){
    this.ngNavigatorShareService.share({
      title:  this.merchand.name,
      text: 'AfrikEcommerce',
      url: this.getUrl(),
    }).then( (response) => {
      console.log(response);
    })
    .catch( (error) => {
      console.log(error);
    });
  }

}
